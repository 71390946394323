.header {
    font-size: 10px;
    font-weight: 100;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.images {
    border: 1px solid;
    height: auto;
    width: 100%;
}
.imgPara {
    text-align: right;
}
.footer {
    text-align: center;
}
.dateTime {
    text-align: center;
    color:purple;
    font-size: 15px;
}

/* Home Screen */
.products {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
}
.products li {
    list-style-type: none;
    padding: 0;
    flex: 0 1 34rem;
    margin: 1rem;
    
    border-bottom: .1rem #c0c0c0 solid;
}

.product{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}