
.bldgs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    
}
.title {
    font-size: 50px;
    font-weight: 100;
    font-style: oblique;
    text-decoration-color: blueviolet;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
@import "react-slideshow-image/dist/styles.css";
/*
.bldgs li{
    list-style-type: none;
    padding: 0;
    flex: 0 1 34rem;
    margin: 1rem;
    height: 50rem;
}
*/
.bldg{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.bldg-image{
    max-width: 34rem;
    max-height: 34rem;
}
.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
